import React from "react";

const About = () => {
  setTimeout(() => {
    window.location.href = "/";
  }, 5000);
  return (
    <div>
      <h1>{404}</h1>
    </div>
  )
}

export default About;
